<template>
  <b-card no-body header-tag="header" footer-tag="footer" id="ai_container">
    <template #header>
      <div class="d-flex justify-content-between">
        <h4 id = "culture_gen_bot_title">AI bot</h4>
        <div>
          <b-button variant="primary" @click="restartConversation">Reprendre</b-button>
          <b-button variant="success" id="close_culture_gen_chat" @click="hideComponent">Fermer</b-button>
        </div>
      </div>
    </template>
    <section class="p-2">
      <div v-for="(message, index) in messages" :key="index"
        :class="message.from === 'user' ? 'userMessageWrapper' : 'AIMessageWrapper'">
        <div :class="message.from === 'user' ? 'messageFromUser' : 'messageFromAI'">
          {{ message.content }}
        </div>
        <a :href="message.link"></a>
      </div>
      <div v-if="isAiTiping" class = "AIMessageWrapper" >
        <div class="messageFromAI">
          <div class="loading">
            <span class="loading__dot"></span>
            <span class="loading__dot"></span>
            <span class="loading__dot"></span>
          </div>
        </div>
      </div>
    </section>
    <template #footer>
      <div class="d-flex">
        <b-form-input :disabled="!sendingMessageActivated ? true : false" v-model="currentMessage" type="text"
          placeholder="" @keyup.enter="sendMessage(currentMessage)" />
        <b-button :disabled="!sendingMessageActivated ? true : false" variant="success"
          @click="sendMessage(currentMessage)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill"
            viewBox="0 0 16 16">
            <path
              d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
          </svg>
        </b-button>
      </div>
    </template>
  </b-card><!-- end left-side -->
</template>

<script>
import axios from 'axios';
import { write } from 'pdfmake/build/pdfmake';
import { Api } from "../../helpers"
import {Content, LLMBOT} from "./LLMUtils"


export default {
  name: 'AiChat',
  props: ['allCultureName'],
  data() {
    return {
      isAiTiping : false,
      sendingMessageActivated: true,
      currentMessage: '',
      messages: [],
      generatingCulture:false,
    };
  },

  mounted() {
    this.messages.push({
      from: 'ai',
      content: "Bonjour, je suis votre assistant virtuel je vais vous aider à créer une fiche de culture.",
    })
    this.messages.push({
      from: 'ai',
      content: "Pourriez-vous me dire quelle culture vous souhaitez réaliser ?",
    })
  },

  methods: {

    async converse(){
      this.isAiTiping = true
      await LLMBOT.post("/api/v1/bot/extractDataFromMessage", this.messages)
      .then(res =>{
        console.log("*********reponse du llm : ", res.data)
        let conversation = res.data
        console.log("voici la reponse finale", conversation)
        if(!conversation.terminated){
          this.writetoUser(conversation.nextMessage)
        }else{
          
          this.sendingMessageActivated = false
          this.writetoUser("Veuillez patienter pendant que je récupère les données sur votre culture...")
          this.isAiTiping = true
          
          
          this.generateCulture(conversation)
          .then(res =>{
            this.writetoUser(`Vous avez de la chance ! \nVotre fiche de culture de ${conversation.name} est disponible sur la section de droite.\nMerci pour votre interet !`)
          })
          this.giveAdvice(conversation)
        }
      })
      .catch(e => {
        console.log(e)
        this.$emit('error', 'Erreur de connexion. vérifiez votre connexion ou réessayez plus tard')
      })
      .then(()=>{
        //TODO: decummenter apres la résolution de l'affichage du spinner 3 dot 
        this.isAiTiping = false
      })
    },

    restartConversation(){
      this.$emit("culture-generated",{})//Pour reinitialiser l'element qui s'affiche sur la partie droite 
      //TODO: ajouter la reinitialisation de la partie droite du bot ouverrt 
      this.sendingMessageActivated= true;
      this.currentMessage= '';
      this.messages= [];
      this.messages.push({
        from: 'ai',
        content: "Bonjour, je suis votre assistant virtuel je vais vous aider à créer une fiche de culture.",
      })
      this.messages.push({
        from: 'ai',
        content: "Pourriez-vous me dire quelle culture vous souhaitez réaliser ?",
      })
    },

    hideComponent() {
      this.$emit('hideComponent')
    },

    async sendMessage(message) {
      if (message == "") return
      this.currentMessage = ""
      this.messages.push({
        from: 'user',
        content: message,
      });
      this.converse()
    },

    async findCultureEveryWere(){
      let content = new Content(this.allCultureName)
      await LLMBOT.post("/findCultureEveryWere",content)
      .then((res)=>{
        let [place, cultureId] = res.data

        switch (place) {
          case 'forUser':
            place = "Vous la touverez dans l'onglet mes cultures"
            break;
          case 'forPIA':
            place = "Vous la touverez dans l'onglet liste de cultures"
            break;
          case 'validated':
            place = "Vous la touverez dans l'onglet liste de cultures sous 'Validées'"
            break;
        }

        let link = '' + '/'//TODO: construire le lien en fonction de la place et de l'ID de la culture 

        return [place, link]
        
      })
      .catch(e => this.$emit('error', 'Une erreur est survenue. Veuillez reessayer plus tard'))

    },

    //TODO: gerer l'affichage des messages avec lien à l'utilisateur
    writetoUser(message, link) {
        //TODO: if(message == "") afficher un message d'erreur à l'utilisateur l'invitant à recharger le tchat et indiquant qu'une erreur est survenue
        if(message != ""){
          this.messages.push({
            from: 'ai',
            content: message,
            link: link
          })
        }
    },

    async giveAdvice(cultureInfos){
      let content ={
        name: cultureInfos.name,
        location: cultureInfos.location,
        isMecanised: cultureInfos.isMecanised
      }
      console.log("contenu du give advice : ", )
      await LLMBOT.post("/giveAdvice",content)
      .then((response)=>{
        console.log("conseil",response.data)
        this.writetoUser(response.data.advice)
      })
      .catch(e => {
          this.$emit('error', 'une erreur est survenue lors de la génération de la culture')
        }
      )
    },

    async generateCulture(cultureInfos){
      let content ={
        name: cultureInfos.name,
        location: cultureInfos.location,
        isMecanised: cultureInfos.isMecanised
      }
      await LLMBOT.post("/generateCulture",content)
      .then((response)=>{
        this.$emit("culture-generated",response.data)
      })
      .catch(e => {
          this.$emit('error', 'une erreur est survenue lors de la création de la culture')
        }
      )
    }
  },
};
</script>

<style scoped>
* {
  --user-message-bg: #4dbd74;
  --AI-message-bg: #7ad1f3;
  --advice-success-bg: blue;
  --advice-danger-bg: red;
}
#advice_container{
  border: 1px solid blue;
}
#advice_message{
  width: 100%;
  padding: 10px;
  margin: 0;
}

.advice-ok{
  background-color: var(--advice-success-bg)
}

.advice-danger{
  background-color: var(--advice-danger-bg);
}

section {
  height: 100%;
  overflow-y: auto;
  background-color: #f6f7f6;
}

.userMessageWrapper,
.AIMessageWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.userMessageWrapper {
  justify-content: flex-end;
}

.AIMessageWrapper {
  justify-content: flex-start;
}

.messageFromUser,
.messageFromAI {
  min-width: 40%;
  max-width: 80%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 15px;
  font-weight: bold;
}

.messageFromUser {
  background-color: var(--user-message-bg);
  color: #fff;
}

.messageFromAI {
  background-color: var(--AI-message-bg);
  color: #000;
}

#close_culture_gen_chat{
  margin-left: 20px;
}
/*BLOC POUR LE AI SPINNER*/
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: #f6f7f6;
  display: inline-block;
  height: 8px;
  aspect-ratio: 1/1;
  margin: 4px;
  border-radius: 50%;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% { background-color: #f6f7f6; transform: scale(1); }
  50% { background-color: #fff; transform: scale(1.3); }
  100% { background-color: #f6f7f6; transform: scale(1); }
}
/*FIN BLOC POUR LE AI SPINNER*/

/* TODO: alligner ce texte  au centre */
#ai_container{
  overflow: hidden;
  height: 100%;
}
</style>