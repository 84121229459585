<template>
  <div>
    <b-dropdown dropleft no-caret variant="ligth">
      <template #button-content class="border-0">
        <BIconThreeDotsVertical />
      </template>

      <div v-if="culture" class="options_container">
        <li v-if="options.includes('alter')" @click="showCultureModal" class="menu_item">
          <i class="fa fa-edit mr-2"></i>Modifier
        </li>
        <li v-if="options.includes('delete')" @click="deleteCulture" class="menu_item_danger">
          <i class="fa fa-trash mr-2"></i> Supprimer
        </li>
        <li v-if="options.includes('import')" @click="importCulture" class="menu_item_success">
          <i class="fa fa-download mr-2"></i> Importer
        </li>
        <div></div>
      </div>
      <div v-else class="options_container">
        <li v-if="options.includes('alter')" @click="showStepModal" class="menu_item">
          <i class="fa fa-edit mr-2"></i>Modifier
        </li>
        <li v-if="options.includes('delete')" @click="deleteStep" class="menu_item_danger">
          <i class="fa fa-trash mr-2"></i> Supprimer
        </li>
        <div></div>
      </div>
    </b-dropdown>
    <b-modal v-if="cultureStep" :ref="'modify-step-modal'+cultureStep.data.id">
      <template #modal-title><i class="fa fa-edit mr-2"></i> Modifier l'étape de culture:
        <b>{{ cultureStep.data.name }}</b>
      </template>
      <b-col offset="1" class="border border-5 border-primary col offset-1 p-4 m-0">
        <b-form>
          <!-- pourquoi lorsqu'on lance la modification, le modal ne veut plus se fermer -->
          <c-input container-class="mb-3 mt-3" label="Nom de l'étape" :placeholder="cultureStep.data.name"
            v-model="stepName">
            Veuillez saisir un nom
          </c-input>
          <c-input container-class="mb-3" type="textarea" label="Commentaire" :placeholder="cultureStep.data.comment"
            v-model="stepComment">
          </c-input>
        </b-form>
      </b-col>
      <template #modal-footer>
        <div class="col-12 d-flex justify-content-end p-0">
          <b-button variant="secondary" class="mr-2" @click="hideStepModal">Annuler</b-button>
          <button-spinner variant="success" type="submit" class="px-4" @click="updateStep" :fetching="updatingStep">
            Modifier<i class="fa fa-save ml-2"></i>
          </button-spinner>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Api, Toast } from "../../../helpers";
import { BIconThreeDotsVertical } from "bootstrap-vue";


export default {
  props: [
    "exploitationId",
    "culture",
    "options",
    "cultureSteps",
    "cultureStep"
  ], //Todo: remove this comment after code completion ...NB componentType = culture||stapeculture etc.
  name: "MenuItem",
  components: {
    BIconThreeDotsVertical
  },
  data() {
    return {
      stepComment:'',
      stepName:'',
      updatingStep: false
    };
  },
  methods: {
    updateStep(){
      this.updatingStep = true
      Api.post("/updateCulture", {})
      .then(res =>{
        this.$refs['modify-step-modal'+this.cultureStep.data.id].hide()
        if (res.data.status === 'success' && res.data.data) {
          Toast.success("Modification enregistrée")
        }else{
          console.log("erreur dans update culture")
          Toast.error(res.data.error)
        }
      })
      .catch(e => {
        this.$refs['modify-step-modal'+this.cultureStep.data.id].hide()
        Toast.error("Problème de connexion. veuillez réessayer plus tard")
      })
      .then(() => this.updatingStep = false)
    },
    deleteStep(){

    },
    showStepModal() {
      this.$refs['modify-step-modal'+this.cultureStep.data.id].show();
    },
    hideStepModal() {
      this.$refs['modify-step-modal'+this.cultureStep.data.id].hide();
    },
    showCultureModal() {
      this.$emit("updating-culture", this.culture);
    },

    deleteCulture() {
      const res = confirm(
        `Etes-vous sûr de vouloir supprimer la culture "${this.name}" ?`
      );
      if (res) {
        Api.post("/exploitation/culture/delete", {
          exploitationId: this.exploitationId,
          cultureId: this.culture.data.id
          //todo: verifier si l'erreur viens de id_culture dans la fonction de base
        })
          .then(res => {
            //TODO: changer le status suivant la convention http de suppression
            if (res.data.status === "success" && res.data.data) {
              this.$emit("delete-success", this.culture.data.id);
            } else {
              this.$emit("not-permit-error", res.data.error);
            }
          })
          .catch(error => {
            this.$emit("operation-error", "Echec de la connexion au serveur");
          });
      }
    },

    importCulture() {
      let cultureSample = {
        exploitationId: this.exploitationId,
        name: this.culture.data.name,
        comment: this.culture.data.comment,
        picture: this.culture.data.picture,
        isOwn: 1,
        cultureSteps: this.cultureSteps
      };
      console.log("exemple de culture", cultureSample);
      Api.post("/exploitation/culture/save", cultureSample)
        .then(res => {
          this.$router.push(
            `/exploitation/${this.exploitationId}/cultures/ownlist`
          );
        })
        .catch(error => {
          this.$emit(
            "operation-error",
            "Une erreur est survenue lors de l'importation de la culture"
          );
        });
    }
  },
  //TODO: valider les données du formulaire
  /* computed: {
    stepNameState() { return !this.submitted || this.stepName.trim().length >= 3 ? null : false },
  } */
};
</script>

<style scoped>
* {
  --bootstrap-blue: #007bff;
  --bootstrap-blue-hover: #0b5ed7;
  --bootstrap-red: #dc3545;
  --bootstrap-red-hover: #bb2d3b;
  --bootstrap-gray-hover: rgba(0, 0, 0, 0.15);
  --bootstrap-green: rgb(25, 135, 84);
  --bootstrap-green-hover: #157347;
}

.full_screen_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.menu_item,
.menu_item_danger,
.menu_item_success {
  cursor: pointer;
  padding: 7px 10px;
}

.menu_item {
  color: var(--bootstrap-blue);
}

.menu_item:hover {
  color: #fff;
  background-color: var(--bootstrap-blue);
}

.menu_item:active {
  color: #fff;
  background-color: var(--bootstrap-blue-hover);
}

.menu_item_danger {
  color: var(--bootstrap-red);
}

.menu_item_danger:hover {
  color: #fff;
  background-color: var(--bootstrap-red);
}

.menu_item_danger:active {
  color: #fff;
  background-color: var(--bootstrap-red-hover);
}

.menu_item_success {
  color: var(--bootstrap-green);
}

.menu_item_success:hover {
  color: #fff;
  background-color: var(--bootstrap-green);
}

.menu_item_success:active {
  color: #fff;
  background-color: var(--bootstrap-green-hover);
}

.options_container {
  position: relative;
}

.options_container>div {
  content: "";
  position: absolute;
  top: 12px;
  right: -5px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border: 1px solid rgb(200, 206, 211);
  border-left: none;
  border-bottom: none;
  background-color: #fff;
  border-bottom-left-radius: 100%;
}
</style>
