<template>
  <div class="culture_container" ref="cultureContainer">
    <div id = "step_culture_columns" role="tablist">
      <h6 class = "fs-6 test_sticky">Etapes</h6>
      <ul>
        <li v-for="(step, index) in culture.cultureSteps" @click="handleStepSelection($event, step)" class = "list_elemnt">
          {{ step.name }}
        </li>
      </ul>
      <div v-if="culture.comment" class="comment_section culture_comment">
        <p>{{culture.comment}}</p>
      </div>
    </div>

    <div id = "activities_columns" role="tablist">
      <h6 class = "fs-6 test_sticky">Activités de l'étape</h6>
      <ul>
        <li v-for="(activity, index) in selectedStep.activities" @click="handleActivitySelection($event, activity)" class="list_elemnt">
          {{ activity.name }}
        </li>
      </ul>
      <div v-if="selectedStep.comment" class="comment_section step_comment">
        <p>{{selectedStep.comment}}</p>
      </div>
    </div>

    <div id = "activities_details_columns" ref="activitiesDetailsColumns" role="tablist">
      <h6 class = "fs-6 test_sticky">Détails sur l'activité</h6>
      <div v-if="Object.keys(selectedActivity).length > 0">
        <div v-if="selectedActivity.comment" class="comment_section activity_comment">
          <p>{{selectedActivity.comment}}</p>
        </div>
        <div id="input_material_card">
          <p>Durée: <span>{{ selectedActivity.duration }}</span></p>
          <p>Coût approximatif: <span>{{ selectedActivity.cost }}</span></p>
          <p>Nombre de personne requis: <span>{{ selectedActivity.personNumber }}</span></p>
        </div>
        <div v-if="selectedActivity.equipments" id = "equipment_container">
          <h6>Equipements</h6>
          <b-table striped :items="selectedActivity.equipments" :fields="[{key:'name', label:'Nom'},{key:'quantity', label:'Quantité'}]"></b-table>
        </div>
        <div v-if="selectedActivity.inputs" id = "input_container">
          <h6>Inputs</h6>
          <b-table striped :items="selectedActivity.inputs" :fields="[{key:'name', label:'Nom'},{key:'quantity', label:'Quantité'}]"></b-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['culture'],
  name: "GeneratedCultureContainer",
  data() {
    return {
      domSelectedStep: {},
      domSelectedActivity: {},
      selectedStep:{name:"", comment:"", activities:[]},
      selectedActivity:{},
      treeData: [],
      cultureFilter: '',
      treeOptions: {
        filter: {
          emptyText: "Aucune culture ne correspond à votre recherche",
          showChildren: true
        }
      },
    }
  },

  methods: {
    handleStepSelection(event, step){
      if (this.domSelectedStep instanceof HTMLElement) {
        this.domSelectedStep.classList.remove("selected")
      }
      this.domSelectedStep = event.target
      this.domSelectedStep.classList.add("selected")

      if(JSON.stringify(this.selectedStep) != JSON.stringify(step)){
        this.selectedStep={name: step.name, comment: step.comment, activities: step.activities}
        this.selectedActivity={}
        if (this.domSelectedActivity instanceof HTMLElement) {
          this.domSelectedActivity.classList.remove("selected")
          this.domSelectedActivity = {}
        }
      }
    },

    handleActivitySelection(event, activity){
      if (this.domSelectedActivity instanceof HTMLElement) {
        this.domSelectedActivity.classList.remove("selected")
      }
      this.domSelectedActivity = event.target
      this.domSelectedActivity.classList.add("selected")

      if(JSON.stringify(this.selectedActivity) != JSON.stringify(activity)){
        this.selectedActivity = activity
      }
    },

    getScrollbarWidth() {
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
        
    }
    
  },
  mounted() {
    let scrolWidth = this.getScrollbarWidth()
    this.$refs.activitiesDetailsColumns.style.marginRight = scrolWidth+"px"//permet l'affichage du scroll de cet element qui sinon est masqué par celui de la page

    //let cultureContainer = this.$refs.cultureContainer
    //cultureContainer.style.height = (cultureContainer.firstChild.scrollHeight + 44 ) + "px"
  },
  watch:{
    culture(){
      this.selectedActivity = {}
      this.selectedStep = {}
    }
  }
} 
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.culture_container {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.culture_container ul{
  margin: 0;
  padding: 0;
}

.culture_container li, h6{
  margin: 0;
  padding: 0.7rem 1.2rem;
}
.culture_container li{
  list-style-type: none;
  text-align: left;
}
h6{
  font-weight: 700;
  border-bottom: 1px solid #c8ced3;
}
.culture_container li:hover{
  background-color: #f0f3f7;
  color: #062c33;
  cursor: pointer;
}

.culture_container>div {
  overflow-y: auto;
}
/* TODO: addoucir les couleurs de h6 */
#input_material_card, #equipment_container, #input_container{
  background-color: #e9ecef;
  margin-top: 20px;
}

#equipment_container > h6 , #input_container > h6{
  padding: 0.7rem 1.2rem;
  font-weight: 700;
}

#input_material_card > p{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0.7rem 1.2rem;
}
#input_material_card > span{
  background-color: #e4e7ea;
  font-size: normal;
  font-weight: normal;
}
.step_div{
  height: 41px;
  display: flex;
  align-items: center;
}
.step_div > p{
  margin: 0;
}
.comment_section{
  padding: 0.7rem;
  color: #062c33;
  font-size: 90%;
}

.activity_comment >p{
  margin: 0;
}

.activity_comment, .step_comment > p, .culture_comment > p{
  background-color: #d1ecf1;
}

.step_comment > p, .culture_comment > p{
  margin: 0;
  background-color: #d1ecf1;
  padding: 10px;
  border-radius: 5px;
}

.step_comment, .culture_comment{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.activity_comment{
  margin-bottom: 30px;
}
#step_culture_columns{
  border: 1px solid #c8ced3;
}
#step_culture_columns .selected, #activities_columns .selected{
  background-color: #e9ecef;
  display: flex;
  justify-content: space-between;
}
#step_culture_columns .selected:hover, #activities_columns .selected:hover{
  background-color: #e9ecef;
}
#step_culture_columns .selected::after, #activities_columns .selected::after{
  content: ">";
  color: rgb(206, 212, 218);

}
#activities_columns, #activities_details_columns{
  border-top: 1px solid #c8ced3;
  border-bottom: 1px solid #c8ced3;
  border-right: 1px solid #c8ced3;
}

#activities_columns, #step_culture_columns{
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}
.test_sticky{
  background-color: #fff;
  position: sticky;
  top: 0px;
}
</style>