import axios from "axios";

export class Content{
    constructor(content, intention = ""){
        this.content = content;
        this.intention = intention;
    }
}

export const LLMBOT = {
    LLMURL : "http://localhost:8080",
    post(endPoint, data) {
        return axios.post(this.LLMURL + endPoint, data)
            .then(response => {
                if (response.data.hasOwnProperty('error')) {
                    return Promise.reject(new Error(response.data.error));
                }
                return response;
            })
    },

    get(endPoint) {
        return axios.get(this.LLMURL + endPoint)
            .then(response => {
                if (response.data.hasOwnProperty('error')) {
                    return Promise.reject(new Error(response.data.error));
                }
                return response;
            })
    }
};